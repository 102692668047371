import { motion } from 'framer-motion';

export const SuccessStory = () => {
    return (
        <section className="py-24 bg-gray-50">
            <div className="max-w-7xl mx-auto px-4">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold mb-4">Success Story</h2>
                    <p className="text-xl text-gray-600">See how others achieved their German language goals</p>
                </div>
                <motion.div 
                    className="bg-white rounded-2xl p-8 md:p-12 shadow-xl border border-gray-100"
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    viewport={{ once: true }}
                >
                    <div className="flex flex-col md:flex-row items-center gap-8 md:gap-12">
                        <div className="relative">
                            <div className="w-32 h-32 md:w-40 md:h-40 rounded-full bg-gradient-to-r from-blue-500 to-purple-500 flex items-center justify-center text-5xl">
                                👨‍💻
                            </div>
                            <div className="absolute -bottom-3 -right-3 bg-green-500 text-white px-4 py-2 rounded-full text-sm font-semibold">
                                Founder
                            </div>
                        </div>
                        <div className="flex-1">
                            <div className="flex flex-col gap-6">
                                <div>
                                    <h3 className="text-2xl md:text-3xl font-bold mb-2">From Zero to B1 in Just 2 Weeks</h3>
                                    <div className="flex flex-wrap gap-3 mb-4">
                                        <span className="text-blue-600 bg-blue-50 px-4 py-1 rounded-full text-sm font-medium">
                                            Software Engineer
                                        </span>
                                        <span className="text-green-600 bg-green-50 px-4 py-1 rounded-full text-sm font-medium">
                                            Passed B1 in 2 Weeks
                                        </span>
                                    </div>
                                </div>
                                <blockquote className="text-gray-600 text-lg leading-relaxed">
                                    "As a busy software engineer working full-time, I struggled to find time for German learning. 
                                    I created this platform to solve my own problem, and using this focused approach with AI-powered feedback, 
                                    I was able to efficiently prepare and pass my B1 exam in just two weeks. The structured practice tests 
                                    and instant feedback made all the difference!"
                                </blockquote>
                                <footer className="flex items-center gap-4 mt-4">
                                    <div>
                                        <p className="font-semibold text-lg">Dayem</p>
                                        <p className="text-gray-500">Creator of GermanPrep</p>
                                    </div>
                                </footer>
                            </div>
                        </div>
                    </div>
                </motion.div>
            </div>
        </section>
    );
}; 