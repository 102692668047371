import { PageProps } from '@/types';
import { Head, Link } from '@inertiajs/react';
import { Button } from '@/Components/ui/button';
import { motion, AnimatePresence } from 'framer-motion';
import { useState, useEffect } from 'react';
import { CountdownTimer } from '@/Components/landing/CountdownTimer';
import { TrustBadges } from '@/Components/landing/TrustBadges';
import { StickyCTA } from '@/Components/landing/StickyCTA';
import { ScrollToTop } from '@/Components/landing/ScrollToTop';
import { SuccessStory } from '@/Components/landing/SuccessStory';
import { Badge } from '@/Components/ui/badge';
import { MagicCursor } from '@/Components/ui/magic-cursor';
import { ShareButton } from '@/Components/ui/share-button';
import { ComparisonTable } from '@/Components/landing/ComparisonTable';
import { ArrowRight } from 'lucide-react';
import { AppPreviewSection } from '@/Components/landing/AppPreviewSection';

const FeaturesSection = () => {
    const [autoplayReading, setAutoplayReading] = useState(true);
    const [autoplayGrammar, setAutoplayGrammar] = useState(true);
    const [autoplayWriting, setAutoplayWriting] = useState(true);

    const [readingAnswer, setReadingAnswer] = useState<string | null>(null);
    const [grammarAnswer, setGrammarAnswer] = useState<string | null>(null);
    const [writingFeedback, setWritingFeedback] = useState(false);

    useEffect(() => {
        if (autoplayReading) {
            const timer = setTimeout(() => {
                setReadingAnswer(READING_DEMO.correctAnswer);
                setTimeout(() => {
                    setReadingAnswer(null);
                    setAutoplayReading(false);
                }, 2000);
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [autoplayReading]);

    useEffect(() => {
        if (autoplayGrammar) {
            const timer = setTimeout(() => {
                setGrammarAnswer(GRAMMAR_DEMO.correctAnswer);
                setTimeout(() => {
                    setGrammarAnswer(null);
                    setAutoplayGrammar(false);
                }, 3000);
            }, 1600);
            return () => clearTimeout(timer);
        }
    }, [autoplayGrammar]);

    useEffect(() => {
        if (autoplayWriting) {
            const timer = setTimeout(() => {
                setWritingFeedback(true);
                setTimeout(() => {
                    setWritingFeedback(false);
                    setAutoplayWriting(false);
                }, 4000);
            }, 1200);
            return () => clearTimeout(timer);
        }
    }, [autoplayWriting]);

    const READING_DEMO = {
        correctAnswer: 'Sport und Gesundheit',
        options: [
            'Gesundes Leben',
            'Sport und Gesundheit',
            'Moderne Medizin'
        ]
    };

    const GRAMMAR_DEMO = {
        correctAnswer: 'bin',
        options: ['habe', 'bin', 'ist']
    };


    return (
                <section className="py-24 bg-white">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        {/* Reading Section */}
                        <motion.div 
                            className="grid lg:grid-cols-2 gap-12 items-center mb-32"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                        >
                            <div className="space-y-6">
                                <h2 className="text-3xl font-weight-[400]">Master Reading Comprehension</h2>
                                <p className="text-xl text-gray-600">Practice with authentic B1-level texts and exam-style questions. Our LesenVerstehen trainer helps you:</p>
                                <ul className="space-y-4">
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-blue-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Understand complex German texts quickly and accurately</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-blue-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Practice with real exam questions and formats</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-blue-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Get instant feedback and explanations</span>
                                    </li>
                                </ul>
                                <Link href={route('register')} className="inline-block">
                                    <Button size="lg">Try Reading Practice</Button>
                                </Link>
                            </div>
                            
                            {/* Interactive Reading Demo Card */}
                            <motion.div 
                                className="relative"
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ 
                                    once: true,
                                    amount: 0.4,
                                    margin: "0px 0px -40% 0px"
                                }}
                                transition={{
                                    type: "spring",
                                    stiffness: 50,
                                    damping: 20,
                                    mass: 0.8
                                }}
                                onViewportEnter={() => {
                                    setAutoplayReading(true);
                                }}
                            >
                                <div className="bg-white rounded-xl shadow-2xl p-6 border border-gray-200">
                                    <div className="flex flex-col gap-6">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold">Reading Exercise</h3>
                                            <span className="text-sm text-gray-500">LesenVerstehen</span>
                                        </div>
                                        
                                        <div className="space-y-4">
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <p className="text-sm">Eine neue Studie zeigt: Sport verbessert die Stimmung und hilft beim Lernen...</p>
                                            </div>
                                            
                                            <div className="space-y-2">
                                                <p className="text-sm font-medium">Select the best title:</p>
                                                <div className="space-y-2">
                                                    {READING_DEMO.options.map((option) => (
                                                        <motion.div
                                                            key={option}
                                                            onClick={() => {
                                                                if (!autoplayReading) {
                                                                    setReadingAnswer(option);
                                                                    if (option === READING_DEMO.correctAnswer) {
                                                                    }
                                                                }
                                                            }}
                                                            className={`p-2 border rounded-lg cursor-pointer transition-colors ${
                                                                readingAnswer === option
                                                                    ? readingAnswer === READING_DEMO.correctAnswer
                                                                        ? 'border-green-500 bg-green-50'
                                                                        : 'border-red-500 bg-red-50'
                                                                    : 'hover:bg-blue-50 hover:border-blue-300'
                                                            }`}
                                                        >
                                                            <p className="text-sm">{option}</p>
                                                        </motion.div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute -z-10 inset-0 bg-gradient-to-r from-blue-50 to-green-50 transform rotate-2 rounded-xl" />
                                {readingAnswer && (
                                    <motion.div
                                        initial={{ opacity: 0, y: 10, scale: 0.95 }}
                                        animate={{ opacity: 1, y: 0, scale: 1 }}
                                        exit={{ opacity: 0, y: -10, scale: 0.95 }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 100,
                                            damping: 15,
                                            mass: 0.8
                                        }}
                                        className={`mt-4 p-2 rounded-lg ${
                                            readingAnswer === READING_DEMO.correctAnswer
                                                ? 'bg-green-100 text-green-700'
                                                : 'bg-red-100 text-red-700'
                                        }`}
                                    >
                                        {readingAnswer === READING_DEMO.correctAnswer
                                            ? '✓ Correct!'
                                            : `✗ The correct answer is: ${READING_DEMO.correctAnswer}`}
                                    </motion.div>
                                )}
                            </motion.div>
                        </motion.div>

                        {/* Writing Section */}
                        <motion.div 
                            className="grid lg:grid-cols-2 gap-12 items-center mb-32"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                        >
                            {/* Interactive Writing Demo Card */}
                            <motion.div 
                                className="relative order-2 lg:order-1"
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ 
                                    once: true,
                                    amount: 0.4,
                                    margin: "0px 0px -40% 0px"
                                }}
                                transition={{
                                    type: "spring",
                                    stiffness: 50,
                                    damping: 20,
                                    mass: 0.8
                                }}
                                onViewportEnter={() => {
                                    setAutoplayWriting(true);
                                }}
                            >
                                <div className="bg-white rounded-xl shadow-2xl p-6 border border-gray-200">
                                    <div className="flex flex-col gap-6">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold">AI Writing Review</h3>
                                            <span className="text-sm text-gray-500">Schreiben</span>
                                        </div>
                                        
                                        <div className="space-y-4">
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <p className="text-sm font-medium mb-2">Your letter:</p>
                                                <p className="text-sm">Liebe Maria, ich schreibe dir, weil ich nächste Woche nach Berlin komme...</p>
                                            </div>
                                            
                                            <Button 
                                                size="sm" 
                                                onClick={() => {
                                                    if (!autoplayWriting) {
                                                        setWritingFeedback(true);
                                                    }
                                                }}
                                                disabled={writingFeedback}
                                            >
                                                Get AI Feedback
                                            </Button>
                                            
                                            <AnimatePresence>
                                                {writingFeedback && (
                                                    <motion.div
                                                        initial={{ opacity: 0, height: 0 }}
                                                        animate={{ opacity: 1, height: 'auto' }}
                                                        exit={{ opacity: 0, height: 0 }}
                                                        className="space-y-2"
                                                    >
                                                        <p className="text-sm font-medium">AI Feedback:</p>
                                                        <motion.div
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            transition={{ delay: 0.2 }}
                                                            className="p-2 bg-blue-50 border border-blue-200 rounded-lg"
                                                        >
                                                            <p className="text-sm">✓ Good structure and greeting</p>
                                                        </motion.div>
                                                        <motion.div
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            transition={{ delay: 0.4 }}
                                                            className="p-2 bg-yellow-50 border border-yellow-200 rounded-lg"
                                                        >
                                                            <p className="text-sm">⚠ Consider using Konjunktiv II</p>
                                                        </motion.div>
                                                        <motion.div
                                                            initial={{ opacity: 0, x: -20 }}
                                                            animate={{ opacity: 1, x: 0 }}
                                                            transition={{ delay: 0.6 }}
                                                            className="p-2 bg-green-50 border border-green-200 rounded-lg"
                                                        >
                                                            <p className="text-sm">💡 Suggestion: "...weil ich nächste Woche nach Berlin kommen würde."</p>
                                                        </motion.div>
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute -z-10 inset-0 bg-gradient-to-r from-purple-50 to-pink-50 transform -rotate-2 rounded-xl" />
                            </motion.div>

                            <div className="space-y-6 order-1 lg:order-2">
                                <h2 className="text-3xl font-weight-[400]">AI-Powered Writing Review</h2>
                                <p className="text-xl text-gray-600">Get instant feedback on your German writing with our AI tutor:</p>
                                <ul className="space-y-4">
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-purple-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Instant grammar and style corrections</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-purple-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Personalized improvement suggestions</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-purple-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Practice with real B1 letter writing tasks</span>
                                    </li>
                                </ul>
                                <Link href={route('register')} className="inline-block">
                                    <Button size="lg">Try Writing Practice</Button>
                                </Link>
                            </div>
                        </motion.div>

                        {/* Grammar Section */}
                        <motion.div 
                            className="grid lg:grid-cols-2 gap-12 items-center"
                            initial={{ opacity: 0 }}
                            whileInView={{ opacity: 1 }}
                            viewport={{ once: true }}
                        >
                            <div className="space-y-6">
                                <h2 className="text-3xl font-weight-[400]">Perfect Your Grammar</h2>
                                <p className="text-xl text-gray-600">Master German grammar with our Sprachbausteine trainer:</p>
                                <ul className="space-y-4">
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Practice with real exam-style exercises</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Detailed explanations for each answer</span>
                                    </li>
                                    <li className="flex items-start gap-3">
                                        <svg className="w-6 h-6 text-green-500 mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                                        </svg>
                                        <span>Focus on B1-level grammar patterns</span>
                                    </li>
                                </ul>
                                <Link href={route('register')} className="inline-block">
                                    <Button size="lg">Try Grammar Practice</Button>
                                </Link>
                            </div>

                            {/* Interactive Grammar Demo Card */}
                            <motion.div 
                                className="relative"
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ 
                                    once: true,
                                    amount: 0.4,
                                    margin: "0px 0px -40% 0px"
                                }}
                                transition={{
                                    type: "spring",
                                    stiffness: 50,
                                    damping: 20,
                                    mass: 0.8
                                }}
                                onViewportEnter={() => {
                                    setAutoplayGrammar(true);
                                }}
                            >
                                <div className="bg-white rounded-xl shadow-2xl p-6 border border-gray-200">
                                    <div className="flex flex-col gap-6">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold">Grammar Exercise</h3>
                                            <span className="text-sm text-gray-500">Sprachbausteine</span>
                                        </div>
                                        
                                        <div className="space-y-4">
                                            <p className="text-sm">Complete the sentence:</p>
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <p className="text-sm">Gestern _____ ich mit meiner Freundin ins Kino gegangen.</p>
                                            </div>
                                            
                                            <div className="space-y-2">
                                                {GRAMMAR_DEMO.options.map((option) => (
                                                    <motion.div
                                                        key={option}
                                                        onClick={() => {
                                                            if (!autoplayGrammar) {
                                                                setGrammarAnswer(option);
                                                                if (option === GRAMMAR_DEMO.correctAnswer) {
                                                                }
                                                            }
                                                        }}
                                                        className={`p-2 border rounded-lg cursor-pointer transition-colors ${
                                                            grammarAnswer === option
                                                                ? grammarAnswer === GRAMMAR_DEMO.correctAnswer
                                                                    ? 'border-green-500 bg-green-50'
                                                                    : 'border-red-500 bg-red-50'
                                                                : 'hover:bg-blue-50 hover:border-blue-300'
                                                        }`}
                                                    >
                                                        <p className="text-sm">{option}</p>
                                                    </motion.div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="absolute -z-10 inset-0 bg-gradient-to-r from-green-50 to-teal-50 transform rotate-1 rounded-xl" />
                                {grammarAnswer && (
                                    <motion.div
                                        initial={{ opacity: 0, y: 10, scale: 0.95 }}
                                        animate={{ opacity: 1, y: 0, scale: 1 }}
                                        exit={{ opacity: 0, y: -10, scale: 0.95 }}
                                        transition={{
                                            type: "spring",
                                            stiffness: 100,
                                            damping: 15,
                                            mass: 0.8
                                        }}
                                        className={`mt-4 p-2 rounded-lg ${
                                            grammarAnswer === GRAMMAR_DEMO.correctAnswer
                                                ? 'bg-green-100 text-green-700'
                                                : 'bg-red-100 text-red-700'
                                        }`}
                                    >
                                        {grammarAnswer === GRAMMAR_DEMO.correctAnswer
                                            ? '✓ Correct! "bin" is used with movement verbs.'
                                            : `✗ The correct answer is "bin". We use "sein" with movement verbs.`}
                                    </motion.div>
                                )}
                            </motion.div>
                        </motion.div>
                    </div>
                </section>
    );
};

const Footer = () => {
    return (
        <footer className="bg-gray-50 border-t border-gray-200 py-12 mt-24">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Legal Information</h3>
                        <ul className="space-y-2">
                            <li>
                                <Link href="/terms" className="text-gray-600 hover:text-gray-900">
                                    Terms & Conditions
                                </Link>
                            </li>
                            <li>
                                <Link href="/privacy" className="text-gray-600 hover:text-gray-900">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link href="/refund" className="text-gray-600 hover:text-gray-900">
                                    Refund Policy
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Refund Policy</h3>
                        <p className="text-gray-600">
                            We offer a 7-day no questions asked refund policy on all our products.
                        </p>
                    </div>
                    <div className="space-y-4">
                        <h3 className="text-lg font-semibold">Company Information</h3>
                        <p className="text-gray-600">
                            Account Owner: Dayem Siddiqui
                        </p>
                    </div>
                </div>
                <div className="mt-8 pt-8 border-t border-gray-200">
                    <p className="text-center text-gray-500">
                        &copy; {new Date().getFullYear()} All rights reserved.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default function Welcome({ auth }: PageProps) {
    const [demoAnswer, setDemoAnswer] = useState('');
    const [demoCorrect, setDemoCorrect] = useState<boolean | null>(null);
    const [currentWord, setCurrentWord] = useState(0);
   

    const demoWords = [
        { english: 'the train station', german: 'Bahnhof', article: 'der' },
        { english: 'the restaurant', german: 'Restaurant', article: 'das' },
        { english: 'the apartment', german: 'Wohnung', article: 'die' },
    ];

   
    // Auto-cycle through demo words
    useEffect(() => {
        if (demoCorrect === null) return;
        
        const timer = setTimeout(() => {
            setDemoCorrect(null);
            setDemoAnswer('');
            setCurrentWord((prev) => (prev + 1) % demoWords.length);
        }, 2000);

        return () => clearTimeout(timer);
    }, [demoCorrect]);

    const checkDemoAnswer = () => {
        const word = demoWords[currentWord];
        const correctAnswer = `${word.article} ${word.german}`;
        const isCorrect = demoAnswer.toLowerCase() === correctAnswer.toLowerCase();
        setDemoCorrect(isCorrect);
        if (isCorrect) {
        }
    };

   

    // Add confetti on successful demo answers
    const handleSuccessfulAnswer = () => {
    };

    return (
        <>
            <Head title="Master German B1 - Your Path to Success" />
            <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
                {/* Navigation - Made responsive */}
                <nav className="fixed top-0 left-0 right-0 z-50 bg-white/80 backdrop-blur-md border-b border-gray-100">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="flex justify-between items-center h-16">
                            <div className="flex items-center">
                                {/* Crop to 30px height */} 
                                <img src="/images/logo.png" 
                                alt="GermanPrep Logo" className="h-56 my-auto" />
                                {/* <span className="text-xl font-weight-[400] text-gray-900">GermanPrep</span> */}
                            </div>
                            <div className="flex items-center gap-2 sm:gap-4">
                                {auth.user ? (
                                    <Link href={route('dashboard')}>
                                        <Button>Dashboard</Button>
                                    </Link>
                                ) : (
                                    <>
                                        <Link href={route('login')}>
                                            <Button variant="outline" className="hidden sm:inline-flex">Log in</Button>
                                        </Link>
                                        <Link href={route('register')}>
                                            <Button>Get Started</Button>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </nav>

                {/* Hero Section with Interactive Demo */}
                <div className="pt-32 pb-16 px-4 sm:px-6 lg:px-8">
                    <div className="max-w-7xl mx-auto">
                        <div className="grid lg:grid-cols-2 gap-12 items-center">
                            {/* Left side - Text Content */}
                            <motion.div 
                                className="text-left"
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5 }}
                            >
                                <motion.h1 
                                    className="text-4xl sm:text-5xl md:text-6xl font-weight-[400] text-gray-900 mb-6"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.2 }}
                                >
                                    Hack Your Telc B1 German Exam
                                </motion.h1>
                                <motion.p 
                                    className="text-xl text-gray-600 mb-8"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                                >
                                    Master German B1 with our intelligent learning system. Practice vocabulary, perfect your grammar, and ace your exam with confidence.
                                </motion.p>
                                <motion.div
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.5, delay: 0.4 }}
                                >
                                    <Link href={route('register')}>
                                        <Button size="lg" className="text-lg px-8">Start Learning Now</Button>
                                    </Link>
                                </motion.div>
                            </motion.div>

                            {/* Right side - Interactive Demo */}
                            <motion.div
                                className="relative"
                                initial={{ opacity: 0, scale: 0.95 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.5, delay: 0.3 }}
                            >
                                <div className="bg-white rounded-xl shadow-2xl p-6 border border-gray-200">
                                    <div className="flex flex-col gap-6">
                                        <div className="flex justify-between items-center">
                                            <h3 className="text-lg font-semibold">Vocabulary Trainer Demo</h3>
                                            <span className="text-sm text-gray-500">Try it out! 👇</span>
                                        </div>
                                        
                                        <div className="text-2xl font-light text-gray-700">
                                            {demoWords[currentWord].english}
                                        </div>

                                        <div className="space-y-4">
                                            <input
                                                type="text"
                                                placeholder="Type the German word with article"
                                                className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                                value={demoAnswer}
                                                onChange={(e) => setDemoAnswer(e.target.value)}
                                                disabled={demoCorrect !== null}
                                            />

                                            <AnimatePresence mode="wait">
                                                {demoCorrect === true && (
                                                    <motion.div
                                                        initial={{ opacity: 0, y: 10 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        exit={{ opacity: 0, y: -10 }}
                                                        className="bg-green-100 text-green-700 p-3 rounded-lg"
                                                    >
                                                        Correct! 🎉
                                                    </motion.div>
                                                )}
                                                {demoCorrect === false && (
                                                    <motion.div
                                                        initial={{ opacity: 0, y: 10 }}
                                                        animate={{ opacity: 1, y: 0 }}
                                                        exit={{ opacity: 0, y: -10 }}
                                                        className="bg-red-100 text-red-700 p-3 rounded-lg"
                                                    >
                                                        Try again! The correct answer is: {demoWords[currentWord].article} {demoWords[currentWord].german}
                                                    </motion.div>
                                                )}
                                            </AnimatePresence>

                                            <Button 
                                                onClick={checkDemoAnswer}
                                                className="w-full"
                                                disabled={demoCorrect !== null || !demoAnswer}
                                            >
                                                Check Answer
                                            </Button>
                                        </div>

                                        <div className="text-xs text-gray-500 text-center">
                                            Hint: Don't forget the article (der, die, das)
                                        </div>
                                    </div>
                                </div>

                                {/* Decorative elements */}
                                <div className="absolute -z-10 inset-0 bg-gradient-to-r from-blue-50 to-purple-50 transform rotate-3 rounded-xl" />
                                <div className="absolute -z-20 inset-0 bg-gradient-to-r from-green-50 to-blue-50 transform -rotate-3 rounded-xl" />
                            </motion.div>
                        </div>
                    </div>
                </div>

                <FeaturesSection />

                <AppPreviewSection />

                {/* Add SuccessStory before the pricing section */}
                <section className="bg-gray-50">
                    <SuccessStory />
                </section>

                {/* Add this section before the pricing section */}
                <section className="py-24 bg-gray-50">
                    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="text-center mb-16">
                            <motion.div
                                initial={{ opacity: 0, y: 20 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                viewport={{ once: true }}
                            >
                                <h2 className="text-4xl font-weight-[400] mb-4">Why Choose GermanPrep?</h2>
                                <p className="text-xl text-gray-600 max-w-2xl mx-auto">
                                    See how GermanPrep compares to traditional learning methods and other online platforms
                                </p>
                            </motion.div>
                        </div>

                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.2 }}
                        >
                            <ComparisonTable />
                        </motion.div>

                        {/* Add a CTA button after the comparison table */}
                        <motion.div 
                            className="text-center mt-12"
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ delay: 0.4 }}
                        >
                            <Link href={route('register')}>
                                <Button size="lg" className="rounded-full">
                                    Get Started Now
                                    <ArrowRight className="ml-2 h-4 w-4" />
                                </Button>
                            </Link>
                        </motion.div>
                    </div>
                </section>

                {/* Pricing Section */}
                <section className="py-24 px-4 bg-white relative overflow-hidden">
                    <div className="absolute inset-0  to-white pointer-events-none" />
                    
                    <div className="max-w-7xl mx-auto">
                        <div className="text-center mb-12">
                            <h2 
                                className="text-4xl font-weight-[400] mb-4 !text-black"
                                style={{
                                    color: 'black'
                                }}
                            >
                                Start Your B1 Journey Today
                            </h2>
                            <p 
                                className="text-xl text-black max-w-2xl mx-auto"
                            >
                                Join thousands of successful students who transformed their German learning
                            </p>
                        </div>

                        <div className="grid lg:grid-cols-2 gap-12 items-center max-w-6xl mx-auto">
                            {/* Price Card */}
                            <motion.div 
                                className="relative"
                                initial={{ opacity: 0, x: -50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                            >
                                <div className="bg-white rounded-2xl shadow-xl border border-gray-100 p-8 relative">
                                    <div className="flex flex-col items-center space-y-6">
                                        {/* Features List */}
                                        <div className="w-full space-y-4">
                                            <motion.div 
                                                className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                                                whileHover={{ 
                                                    x: 5, 
                                                    scale: 1.01,
                                                    transition: {
                                                        type: "spring",
                                                        stiffness: 400,
                                                        damping: 17
                                                    }
                                                }}
                                            >
                                                <div className="flex-shrink-0 w-10 h-10 bg-blue-50 rounded-full flex items-center justify-center">
                                                    📚
                                                </div>
                                                <div className="flex-1">
                                                    <div className="flex items-center gap-2">
                                                        <h4 className="font-medium">Unlimited Practice Tests</h4>
                                                        <Badge variant="secondary" className="bg-blue-50 text-blue-700 hover:bg-blue-100">
                                                            Included
                                                        </Badge>
                                                    </div>
                                                    <p className="text-sm text-gray-600">Access all mock exams and exercises</p>
                                                </div>
                                            </motion.div>

                                            <motion.div 
                                                className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                                                whileHover={{ 
                                                    x: 5, 
                                                    scale: 1.01,
                                                    transition: {
                                                        type: "spring",
                                                        stiffness: 400,
                                                        damping: 17
                                                    }
                                                }}
                                            >
                                                <div className="flex-shrink-0 w-10 h-10 bg-green-50 rounded-full flex items-center justify-center">
                                                    🤖
                                                </div>
                                                <div className="flex-1">
                                                    <div className="flex items-center gap-2">
                                                        <h4 className="font-medium">AI Writing Feedback</h4>
                                                        <Badge variant="secondary" className="bg-green-50 text-green-700 hover:bg-green-100">
                                                            Premium
                                                        </Badge>
                                                    </div>
                                                    <p className="text-sm text-gray-600">Get instant corrections and suggestions</p>
                                                </div>
                                            </motion.div>

                                            <motion.div 
                                                className="flex items-center gap-3 p-3 rounded-lg hover:bg-gray-50 transition-colors"
                                                whileHover={{ 
                                                    x: 5, 
                                                    scale: 1.01,
                                                    transition: {
                                                        type: "spring",
                                                        stiffness: 400,
                                                        damping: 17
                                                    }
                                                }}
                                            >
                                                <div className="flex-shrink-0 w-10 h-10 bg-purple-50 rounded-full flex items-center justify-center">
                                                    ✍️
                                                </div>
                                                <div className="flex-1">
                                                    <div className="flex items-center gap-2">
                                                        <h4 className="font-medium">Progress Tracking</h4>
                                                        <Badge variant="secondary" className="bg-purple-50 text-purple-700 hover:bg-purple-100">
                                                            Included
                                                        </Badge>
                                                    </div>
                                                    <p className="text-sm text-gray-600">Monitor your improvement over time</p>
                                                </div>
                                            </motion.div>
                                        </div>

                                        {/* CTA Button */}
                                        <div className="w-full space-y-4">
                                            <Link href={route('register')} className="block">
                                                <motion.button
                                                    className="w-full bg-black text-white py-4 rounded-xl font-weight-[400] text-lg shadow-lg transition-all"
                                                    whileHover={{ 
                                                        scale: 1.02,
                                                        transition: {
                                                            type: "spring",
                                                            stiffness: 400,
                                                            damping: 10
                                                        }
                                                    }}
                                                    whileTap={{ 
                                                        scale: 0.98,
                                                        transition: {
                                                            type: "spring",
                                                            stiffness: 400,
                                                            damping: 17
                                                        }
                                                    }}
                                                >
                                                    Get Started Now →
                                                </motion.button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>

                            {/* Success Metrics */}
                            <motion.div 
                                className="relative"
                                initial={{ opacity: 0, x: 50 }}
                                whileInView={{ opacity: 1, x: 0 }}
                                viewport={{ once: true }}
                            >
                                <div className="bg-gray-50 rounded-2xl p-8">
                                    <h3 className="text-2xl font-weight-[400] mb-8">What You'll Master</h3>
                                    
                                    <div className="space-y-6">
                                        <motion.div 
                                            className="flex items-center gap-4 bg-white p-4 rounded-xl shadow-sm"
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <div className="w-12 h-12 bg-blue-50 rounded-full flex items-center justify-center text-2xl">
                                                📝
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="font-medium mb-2">Pass B1 Exam</h4>
                                                <div className="w-full bg-gray-100 rounded-full h-2">
                                                    <motion.div 
                                                        className="bg-blue-500 h-2 rounded-full"
                                                        initial={{ width: 0, scale: 0.98 }}
                                                        whileInView={{ width: '100%', scale: 1 }}
                                                        transition={{ 
                                                            width: {
                                                                type: "spring",
                                                                stiffness: 30,
                                                                damping: 20,
                                                                mass: 1
                                                            },
                                                            scale: {
                                                                type: "spring",
                                                                stiffness: 100,
                                                                damping: 15,
                                                                delay: 0.2
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <span className="text-sm font-medium text-blue-600">100%</span>
                                        </motion.div>

                                        <motion.div 
                                            className="flex items-center gap-4 bg-white p-4 rounded-xl shadow-sm"
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <div className="w-12 h-12 bg-green-50 rounded-full flex items-center justify-center text-2xl">
                                                💬
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="font-medium mb-2">Speaking Confidence</h4>
                                                <div className="w-full bg-gray-100 rounded-full h-2">
                                                    <motion.div 
                                                        className="bg-green-500 h-2 rounded-full"
                                                        initial={{ width: 0, scale: 0.98 }}
                                                        whileInView={{ width: '90%', scale: 1 }}
                                                        transition={{ 
                                                            width: {
                                                                type: "spring",
                                                                stiffness: 30,
                                                                damping: 20,
                                                                mass: 1
                                                            },
                                                            scale: {
                                                                type: "spring",
                                                                stiffness: 100,
                                                                damping: 15,
                                                                delay: 0.2
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <span className="text-sm font-medium text-green-600">90%</span>
                                        </motion.div>

                                        <motion.div 
                                            className="flex items-center gap-4 bg-white p-4 rounded-xl shadow-sm"
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <div className="w-12 h-12 bg-purple-50 rounded-full flex items-center justify-center text-2xl">
                                                ✍️
                                            </div>
                                            <div className="flex-1">
                                                <h4 className="font-medium mb-2">Writing Skills</h4>
                                                <div className="w-full bg-gray-100 rounded-full h-2">
                                                    <motion.div 
                                                        className="bg-purple-500 h-2 rounded-full"
                                                        initial={{ width: 0, scale: 0.98 }}
                                                        whileInView={{ width: '95%', scale: 1 }}
                                                        transition={{ 
                                                            width: {
                                                                type: "spring",
                                                                stiffness: 30,
                                                                damping: 20,
                                                                mass: 1
                                                            },
                                                            scale: {
                                                                type: "spring",
                                                                stiffness: 100,
                                                                damping: 15,
                                                                delay: 0.2
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <span className="text-sm font-medium text-purple-600">95%</span>
                                        </motion.div>
                                    </div>

                                    {/* Trust Indicators */}
                                    <div className="mt-8 pt-8 border-t border-gray-200">
                                        <div className="grid grid-cols-2 gap-6">
                                            <div className="flex items-center gap-3">
                                                <div className="w-12 h-12 bg-amber-50 rounded-full flex items-center justify-center text-2xl">
                                                    ⭐
                                                </div>
                                                <div>
                                                    <div className="font-medium">4.9/5 Rating</div>
                                                    <div className="text-sm text-gray-500">500+ reviews</div>
                                                </div>
                                            </div>
                                            <div className="flex items-center gap-3">
                                                <div className="w-12 h-12 bg-green-50 rounded-full flex items-center justify-center text-2xl">
                                                    🎯
                                                </div>
                                                <div>
                                                    <div className="font-medium">93% Success</div>
                                                    <div className="text-sm text-gray-500">Pass rate</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>
                </section>

                {/* Social Proof Section */}
                <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
                    <div className="max-w-7xl mx-auto text-center">
                        <h2 className="text-2xl font-semibold mb-8">Trusted by German Learners Worldwide</h2>
                        <div className="grid md:grid-cols-3 gap-8">
                            <div className="bg-white p-6 rounded-lg shadow-sm">
                                <p className="text-gray-600 mb-4">"This platform helped me pass my B1 exam on the first try. The practice materials are spot-on!"</p>
                                <p className="font-medium">- Sarah K.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg shadow-sm">
                                <p className="text-gray-600 mb-4">"The vocabulary trainer is amazing. It's like having a personal tutor."</p>
                                <p className="font-medium">- Michael R.</p>
                            </div>
                            <div className="bg-white p-6 rounded-lg shadow-sm">
                                <p className="text-gray-600 mb-4">"Best investment I've made in my German learning journey. Worth every penny!"</p>
                                <p className="font-medium">- Anna M.</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-16 px-4 sm:px-6 lg:px-8 bg-gray-50">
                    <div className="max-w-3xl mx-auto text-center">
                        <h2 className="text-3xl font-weight-[400] mb-6">Ready to Master German?</h2>
                        <p className="text-xl text-gray-600 mb-8">Join thousands of successful students who have passed their B1 exam with our platform.</p>
                        <Link href={route('register')}>
                            <Button size="lg" className="text-lg px-8">Get Started Free</Button>
                        </Link>
                    </div>
                </section>

                {/* Add this before the footer */}
                <section className="relative py-24 overflow-hidden">
                    {/* Background with subtle pattern */}
                    <div className="absolute inset-0 bg-gradient-to-br from-gray-900 to-gray-800">
                        <div className="absolute inset-0 opacity-5">
                            <div className="absolute inset-0" style={{
                                backgroundImage: `url("data:image/svg+xml,%3Csvg width='60' height='60' viewBox='0 0 60 60' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath d='M36 34v-4h-2v4h-4v2h4v4h2v-4h4v-2h-4zm0-30V0h-2v4h-4v2h4v4h2V6h4V4h-4zM6 34v-4H4v4H0v2h4v4h2v-4h4v-2H6zM6 4V0H4v4H0v2h4v4h2V6h4V4H6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
                            }} />
                        </div>
                    </div>

                    <div className="relative max-w-4xl mx-auto px-4 text-center">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            className="space-y-12"
                        >
                            {/* Main Content */}
                            <div className="space-y-6">
                                <h2 className="text-4xl md:text-5xl font-weight-[400] text-white tracking-tight">
                                    Ready to Transform Your German Learning?
                                </h2>
                                <p className="text-xl text-gray-300 max-w-2xl mx-auto leading-relaxed">
                                    Join me and thousands of others who've successfully mastered B1 German.
                                    Don't miss out on this limited-time offer.
                                </p>
                            </div>

                            {/* CTA Button */}
                            <div className="space-y-8">
                                <Link href={route('register')}>
                                    <motion.button
                                        whileHover={{ scale: 1.02 }}
                                        whileTap={{ scale: 0.98 }}
                                        className="bg-white hover:bg-gray-50 text-gray-900 px-12 py-4 rounded-xl text-lg font-weight-[400] shadow-lg hover:shadow-xl transition-all duration-200"
                                    >
                                        Get Started Now →
                                    </motion.button>
                                </Link>

                                {/* Trust Badges */}
                                <div className="pt-8 border-t border-white/10">
                                    <div className="flex flex-wrap justify-center gap-12 items-center">
                                        <div className="flex items-center gap-2 text-gray-300">
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                                            </svg>
                                            <span className="text-sm">SSL Secure</span>
                                        </div>
                                        <div className="flex items-center gap-2 text-gray-300">
                                            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                                            </svg>
                                            <span className="text-sm">Secure Payment</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </div>
                </section>

                {/* Footer */}
                <Footer />
                <footer className="bg-white border-t border-gray-100 py-16 px-4">
                    <div className="max-w-7xl mx-auto">
                        <div className="grid grid-cols-1 md:grid-cols-4 gap-12 mb-12">
                            <div className="md:col-span-2">
                                <h3 className="text-xl font-weight-[400] text-gray-900 mb-4">GermanPrep</h3>
                                <p className="text-gray-600 max-w-md">
                                    Your intelligent companion for B1 German exam preparation. 
                                    Expert-designed practice materials with AI-powered feedback to ensure your success.
                                </p>
                                <div className="mt-6 flex gap-4">
                                    <Link 
                                        href={route('register')} 
                                        className="text-sm text-blue-600 hover:text-blue-700 font-medium"
                                    >
                                        Start Learning →
                                    </Link>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-sm font-semibold text-gray-900 mb-4">Quick Links</h3>
                                <ul className="space-y-3">
                                    <li>
                                        <Link 
                                            href={route('register')} 
                                            className="text-gray-600 hover:text-gray-900 text-sm"
                                        >
                                            Get Started
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            href={route('login')} 
                                            className="text-gray-600 hover:text-gray-900 text-sm"
                                        >
                                            Sign In
                                        </Link>
                                    </li>
                                    <li>
                                        <a 
                                            href="#features" 
                                            className="text-gray-600 hover:text-gray-900 text-sm"
                                        >
                                            Features
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-sm font-semibold text-gray-900 mb-4">Contact</h3>
                                <ul className="space-y-3">
                                    <li>
                                        <a 
                                            href="mailto:hello@germanprep.com" 
                                            className="text-gray-600 hover:text-gray-900 text-sm flex items-center gap-2"
                                        >
                                            <svg 
                                                className="w-4 h-4" 
                                                fill="none" 
                                                stroke="currentColor" 
                                                viewBox="0 0 24 24"
                                            >
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                            </svg>
                                            hello@germanprep.com
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-sm font-semibold text-gray-900 mb-4">Legal Information</h3>
                                <ul className="space-y-3">
                                    <li>
                                        <Link 
                                            href="/terms" 
                                            className="text-gray-600 hover:text-gray-900 text-sm"
                                        >
                                            Terms & Conditions
                                        </Link>
                                    </li>
                                    <li>
                                        <Link 
                                            href="/privacy" 
                                            className="text-gray-600 hover:text-gray-900 text-sm"
                                        >
                                            Privacy Policy
                                        </Link>
                                    </li>
                                </ul>
                                <div className="mt-4">
                                    <p className="text-sm text-gray-600">Account Owner: Dayem Siddiqui</p>
                                </div>
                            </div>
                        </div>

                        {/* Add this legal disclaimer section before the final copyright div */}
                        <div className="py-8 border-t border-gray-100 mt-8">
                            <div className="max-w-3xl mx-auto text-center">
                                <p className="text-sm text-gray-500 leading-relaxed">
                                    <strong>Legal Disclaimer:</strong> GermanPrep is an independent educational platform and is not affiliated with, endorsed by, or connected to telc GmbH, Goethe-Institut, or any official German examination body. All references to B1 examinations and German language certifications are for informational purposes only. TELC® and any related trademarks are the property of their respective owners. We provide independent preparation materials and learning resources to help students study for their German language examinations.
                                </p>
                            </div>
                        </div>

                        <div className="pt-8 border-t border-gray-100">
                            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
                                <p className="text-sm text-gray-500">
                                    &copy; {new Date().getFullYear()} GermanPrep. All rights reserved.
                                </p>
                                <div className="flex items-center gap-6">
                                    <a 
                                        href="/privacy" 
                                        className="text-sm text-gray-500 hover:text-gray-900"
                                    >
                                        Privacy Policy
                                    </a>
                                    <a 
                                        href="/terms" 
                                        className="text-sm text-gray-500 hover:text-gray-900"
                                    >
                                        Terms of Service
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>

                {/* Add right before the closing fragment */}
                <ScrollToTop />
            </div>

            {/* Add MagicCursor */}
            <MagicCursor />

            {/* Add ShareButton */}
            <ShareButton />
        </>
    );
}
