import React from 'react';
import { InfoIcon, ArrowRight } from "lucide-react";
import { Button } from '@/Components/ui/button';
import { Link } from '@inertiajs/react';
import { AnimatedGroup } from '@/Components/ui/animated-group';

export const AppPreviewSection = () => {
    return (
        <div className="py-16 bg-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="text-center mb-12">
                    <h2 className="text-3xl font-bold text-gray-900">Experience the Full App</h2>
                    <p className="text-lg text-gray-600">Comprehensive tools to master every aspect of German B1</p>
                </div>
                
                {/* Preview Cards - Exact replicas of app components */}
                <AnimatedGroup className="flex flex-col lg:flex-row gap-8 lg:gap-4 items-center justify-center max-w-[1000px] mx-auto mb-16" preset="scale">
                    {/* Reading Practice Preview - Matches ReadingTab */}
                    <div className="p-6 py-4 rounded-lg mx-auto w-[320px] h-[450px] bg-card text-card-foreground overflow-x-hidden shadow-sm bg-sky-300 relative">
                        <div className="flex flex-row justify-between p-0">
                            <h3 className="text-lg font-semibold">Teil 1: GlobalVerstehen</h3>
                            <div className="text-black">
                                <InfoIcon className="h-4 w-4 bg-white rounded-full" />
                            </div>
                        </div>
                        <div className='flex flex-col h-[calc(100%-2rem)]'>
                            <div className="flex-1 -mr-12 bg-slate-100 ml-4 p-4 mt-6 mb-4 rounded-lg shadow-md">
                                <div className="w-full flex flex-col justify-start gap-3">
                                    <div>
                                        <div className="text-sm font-medium mb-1">Select the best title:</div>
                                        <div className="text-sm font-light whitespace-normal mb-2">Eine neue Studie zeigt: Sport verbessert die Stimmung und steigert die Konzentration bei Studenten.</div>
                                    </div>
                                    <div className="flex flex-col gap-1.5">
                                        <div className="w-full border-2 border-zinc-200 bg-white rounded-md p-1 px-2 text-xs cursor-pointer hover:bg-sky-50 transition-colors duration-200 hover:border-sky-500">
                                            Gesundes Leben im Alter
                                        </div>
                                        <div className="w-full border-2 bg-emerald-50 border-emerald-500 rounded-md p-1 px-2 text-xs cursor-pointer hover:bg-sky-50 transition-colors duration-200 hover:border-sky-500">
                                            Sport und Lernerfolg
                                        </div>
                                        <div className="w-full border-2 border-zinc-200 bg-white rounded-md p-1 px-2 text-xs cursor-pointer hover:bg-sky-50 transition-colors duration-200 hover:border-sky-500">
                                            Moderne Fitnesstrends
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-end pb-2">
                                <Link href={route('register')}>
                                    <Button size="sm">Start Practice</Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Grammar Trainer Preview - Matches SentenceTrainingCard */}
                    <div className="p-6 py-4 rounded-lg mx-auto w-[320px] h-[450px] bg-card text-card-foreground overflow-x-hidden shadow-sm bg-green-300 relative">
                        <div className="flex flex-row justify-between p-0">
                            <h3 className="text-lg font-semibold">Sentence Flashcards</h3>
                            <div className="text-black">
                                <InfoIcon className="h-4 w-4 bg-white rounded-full" />
                            </div>
                        </div>
                        <div className='flex flex-col h-[calc(100%-2rem)]'>
                            <div className="flex-1 -mr-12 bg-slate-100 ml-4 p-4 mt-6 mb-4 rounded-lg shadow-md">
                                <div className="w-full h-full flex flex-col">
                                    <div className="text-sm font-medium mb-4">Practice sentence structure with flashcards:</div>
                                    <div className="flex-1 relative w-full bg-white rounded-lg shadow-md p-4 flex items-center justify-center transform hover:scale-105 transition-transform cursor-pointer mb-4">
                                        <div className="text-sm text-center">
                                            Ich <span className="text-sky-600 font-medium">gehe</span> morgen ins Kino.
                                        </div>
                                        <div className="absolute -right-3 -bottom-3 bg-sky-100 text-xs px-2 py-1 rounded-full border border-sky-200">
                                            Present Tense
                                        </div>
                                    </div>
                                    <div className="text-xs text-gray-600">
                                        • Master sentence patterns
                                        <br />
                                        • Practice word order
                                        <br />
                                        • Learn common expressions
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-end pb-2">
                                <Link href={route('register')}>
                                    <Button size="sm">Start Training</Button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    {/* Vocabulary Preview - Matches WordTrainingCard */}
                    <div className="p-6 py-4 rounded-lg mx-auto w-[320px] h-[450px] bg-card text-card-foreground overflow-x-hidden shadow-sm bg-yellow-300 relative">
                        <div className="flex flex-row justify-between p-0">
                            <h3 className="text-lg font-semibold">Vocabulary Flashcards</h3>
                            <div className="text-black">
                                <InfoIcon className="h-4 w-4 bg-white rounded-full" />
                            </div>
                        </div>
                        <div className='flex flex-col h-[calc(100%-2rem)]'>
                            <div className="flex-1 -mr-12 bg-slate-100 ml-4 p-4 mt-6 mb-4 rounded-lg shadow-md">
                                <div className="w-full h-full flex flex-col">
                                    <div className="text-sm font-medium mb-4">Build your vocabulary with flashcards:</div>
                                    <div className="flex-1 relative w-full bg-white rounded-lg shadow-md p-4 flex items-center justify-center transform hover:scale-105 transition-transform cursor-pointer mb-4">
                                        <div className="text-center">
                                            <div className="text-lg font-medium text-yellow-700">der Bahnhof</div>
                                            <div className="text-sm text-gray-600">the train station</div>
                                        </div>
                                        <div className="absolute -right-3 -bottom-3 bg-yellow-100 text-xs px-2 py-1 rounded-full border border-yellow-200">
                                            Noun
                                        </div>
                                    </div>
                                    <div className="text-xs text-gray-600">
                                        • Learn essential words
                                        <br />
                                        • Practice articles
                                        <br />
                                        • Review with spaced repetition
                                    </div>
                                </div>
                            </div>
                            <div className="w-full flex justify-end pb-2">
                                <Link href={route('register')}>
                                    <Button size="sm">Start Learning</Button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </AnimatedGroup>

                {/* Call to Action */}
                <div className="text-center mt-12 max-w-2xl mx-auto">
                    <h3 className="text-2xl font-semibold text-gray-900 mb-4">Ready to Start Your German Journey?</h3>
                    <p className="text-gray-600 mb-8">Join thousands of students who are already improving their German with our comprehensive learning tools.</p>
                    <Link href={route('register')}>
                        <Button size="lg" className="px-8 py-6 text-lg gap-2">
                            Start Learning Now
                            <ArrowRight className="w-5 h-5" />
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
