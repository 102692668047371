import { motion } from 'framer-motion';
import { Share } from 'lucide-react';

export const ShareButton = () => {
    const shareUrl = window.location.href;
    const shareText = "I just found this amazing German learning platform! 🚀";
    
    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'GermanPrep',
                    text: shareText,
                    url: shareUrl,
                });
            } catch (err) {
                console.error('Error sharing:', err);
            }
        } else {
            // Fallback for browsers that don't support Web Share API
            navigator.clipboard.writeText(`${shareText} ${shareUrl}`);
        }
    };

    return (
        <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={handleShare}
            className="fixed bottom-24 right-4 flex items-center gap-2 px-4 py-2 bg-white rounded-full shadow-lg hover:shadow-xl transition-all"
        >
            <Share className="w-4 h-4" />
            <span className="text-sm">Share</span>
        </motion.button>
    );
}; 