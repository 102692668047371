import { motion } from 'framer-motion';
import { useState, useEffect } from 'react';

export const MagicCursor = () => {
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const updateMousePosition = (e: MouseEvent) => {
            setMousePosition({ x: e.clientX, y: e.clientY });
            setIsVisible(true);
        };

        const handleMouseEnter = () => setIsVisible(true);
        const handleMouseLeave = () => setIsVisible(false);

        window.addEventListener('mousemove', updateMousePosition);
        document.addEventListener('mouseenter', handleMouseEnter);
        document.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            window.removeEventListener('mousemove', updateMousePosition);
            document.removeEventListener('mouseenter', handleMouseEnter);
            document.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    if (!isVisible) return null;

    return (
        <>
            {/* Inner cursor */}
            <motion.div
                className="fixed w-3 h-3 bg-gradient-to-r from-blue-500 to-purple-500 rounded-full pointer-events-none z-[9999]"
                animate={{
                    x: mousePosition.x - 6,
                    y: mousePosition.y - 6,
                    transition: {
                        type: "spring",
                        damping: 30,
                        mass: 0.5,
                        stiffness: 400
                    }
                }}
            />
            {/* Outer cursor */}
            <motion.div
                className="fixed w-8 h-8 bg-gradient-to-r from-blue-400/20 to-purple-400/20 rounded-full pointer-events-none z-[9998] backdrop-blur-sm"
                animate={{
                    x: mousePosition.x - 16,
                    y: mousePosition.y - 16,
                    transition: {
                        type: "spring",
                        damping: 40,
                        mass: 0.8,
                        stiffness: 300
                    }
                }}
            />
        </>
    );
}; 